<template>
  <div class="w1092 pt60">
    <div class="board">
      <div class="boardrd first">
        <span class="nicon ignbg ml10">일반</span>
        <span class="ml10"># 비정상 게임이용 제재안내 #</span>
      </div>
      <div class="boardrd">
        <span>win</span><span class="ml10">2025.04.02 14:23</span>
      </div>
      <div class="boardbox">
        <pre>
저희 카지노는 정상적인 라이브 카지노와 동일한 룰로 운영되고있습니다.
이러한 운영룰은 개개인이 정상적인 방법으로 게임하는 경우를 전제로 하는 것입니다.
따라서 개인 또는 다수에 의한, 조직적이고 지속반복적인 형태의 비정상적인
시스템 배팅 및 마틴 배팅,양방 배팅 등은 허용되지 않습니다.
회원님의 배팅 상황은 센터의 모니터링 과정에서 대부분 발견되지만,
설령 발견이 안되어도 회원님이 출금을 신청하시면 출금 절차상 반드시 모든 배팅 내역을 확인한 후에
출금이 진행되도록 되어있기에 이러한 비정상적인 배팅 내역은 반드시 발견되게 됩니다.

(구체적 사례 :예시)

-배팅의 50% 이상을 마틴배팅으로 게임을 진행하는 경우

-지속적으로 플레이어 혹은 지속적으로 뱅커에만 배팅을 하는경우

-회원 2인 이상이 동일 테이블또는 같은 회차에 마틴,양방배팅을 하는 경우

-배팅의 50% 이상을 찍어먹기 배팅으로 게임을 진행하는 경우

-본사 내부 또는 타 카지노와 게임을 진행하는 경우

-특정 프로그램 또는 특정 계산방식에 근거한 시스템 배팅을 하는 경우

-멀티플레이로 배팅을 하는 경우 ( 블랙잭 이용시  한테이블 한구멍만배팅가능)

이러한 비정상적인 배팅 사례가 발생할 경우 사전 통보없이 회원님의 게임 전부와 원금을 전액 몰수(원칙)
할 수 있습니다. 모니터링 과정엣 발견된 경우 1회 경고를 하는 경우도 있지만, 심한 경우 발견 즉시 사전 통보없이
게임을 중지(무효)시키고 원금 몰수 ,계정삭제등을 진행 합니다.

본사의 모든 게임을 정상적인 방법으로 즐겨 주시기 바라오며, 저희 운영진들은 회원님이 편안하게
게임을 즐기실 수 있도록 더욱 최선을 다하겠습니다. 감사합니다.
        </pre>
      </div>
    </div>

    <div class="boardbtn">
      <!-- <a class="blsbtn" v-if="prevId" @click="onClickPrevNext('prev')">{{$t('front.board.prev')}}</a> -->
      <a class="blsbtn" @click="goPageByName('noticeList')">{{$t('front.board.list')}}</a>
      <!-- <a class="blsbtn" v-if="nextId" @click="onClickPrevNext('next')">{{$t('front.board.next')}}</a> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'read2',
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
